@import "../../global.scss";

.intro {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @include mobile{
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;
    @include mobile{
      flex: 0.7;
      padding-bottom: 20px;
      padding-top: 60px;

    }
    .imgContainer {
      width: 400px;
      height: 400px;
      background-color: crimson;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;
      overflow: hidden;

      @include mobile{
        align-items: flex-start;
        background-color: rgb(255, 255, 255);
      }

      img {
        height: 90%;
        transform: scale(1.2);

        @include mobile{
          margin-top: 50px;
          transform: scale(1.5);
          width: 200px;
          height: 200px;
          border-radius: 100%;
          align-items: flex-end;
        }
      }
    }
  }
  .right {
    flex: 0.7;
    position: relative;
    padding-left: 120px;
    @include mobile{
      padding-left: 0;
      align-items: center;
    }

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile{
        padding-left: 0;
        align-items: center;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;

        @include mobile{
          font-size: 40px;
        }
      }
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 30px;

        @include mobile{
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}